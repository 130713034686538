<template>
  <v-layout align-center justify-center fill-height class="grey lighten-5">
    <v-card
        :min-width="$vuetify.breakpoint.smAndUp ? 360 : 280"
        outlined
    >
      <v-card-text>
        <v-form ref="form" v-model="valid" @submit.prevent="submit" aria-autocomplete="none" autocomplete="off">
          <v-text-field
              filled
              label="Login"
              required
              class="mb-3"
              v-model="username"
              :hide-details="!hasErrors('username')"
              :error-messages="getErrors('username')"
              @input="unsetError('username')"
              :rules="loginRules"
          ></v-text-field>
          <v-text-field
              type="password"
              filled
              label="Password"
              required
              class="mb-3"
              v-model="password"
              :hide-details="!hasErrors('password')"
              :error-messages="getErrors('password')"
              @input="unsetError('password')"
              :rules="passwordRules"
          ></v-text-field>
          <v-btn block
                 x-large
                 depressed
                 type="submit"
                 :plain="loading"
                 :disabled="loading"
                 :loading="loading"
                 color="secondary">
            Log In
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-layout>
</template>

<script>
import {mapActions, mapState, mapGetters} from "vuex";

export default {
  name: "Login",
  data: () => ({
    username: '',
    password: '',
    loginRules: [
      v => !!v || 'The username is required.',
    ],
    passwordRules: [
      v => !!v || 'The password is required.',
    ],
    valid: false,
  }),
  computed: {
    ...mapState('auth', {
      loading: 'loading'
    }),
    ...mapGetters('error', {
      hasErrors: 'hasErrors',
      getErrors: 'getErrors',
    }),
  },
  methods: {
    ...mapActions('auth', {
      signIn: 'login'
    }),
    ...mapActions('error', {
      unsetError: 'unset'
    }),
    submit() {
      this.signIn(this.$data);
    }
  }
}
</script>